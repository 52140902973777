import getConfig from "highline/config/application"
import axios from "axios"

const { isFeatureMode } = getConfig()

export const createClient = (baseURL) =>
  axios.create({
    baseURL,
    headers: {
      "Accept": "application/json",
    },
    validateStatus: (status) => {
      return status < 400 // only error for 400 and up
    },
    withCredentials: false, // explicitly setting to false for highline-admin because we directly hit flatiron apis
  })
